// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCz0J4QHATiOfEXOOaMMYBlBqNgcLHpNsk",
  authDomain: "verynewecommerceseo.firebaseapp.com",
  projectId: "verynewecommerceseo",
  storageBucket: "verynewecommerceseo.appspot.com",
  messagingSenderId: "908079318722",
  appId: "1:908079318722:web:978b8bece05b5ee6a2dd5b"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;